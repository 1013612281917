import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "../../assets/images/logo.png";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import PlanCard from "../../components/SubscriptionCard";
import instance from "../../redux/actions/AxiosInstance/authenticated";

const PaymentTables = () => {
  const [plansData, setPlansData] = useState();
  const [isLoading, setIsLoading] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  // const user = useSelector((state) => state.auth.user);

  //   const newUser = useSelector((state) => state.auth.newUser);

  const getPlanDetails = async () => {
    try {
      const res = await instance.get(
        process.env.REACT_APP_BASE_URL + "/whop/get-plans"
      );
      if (res.data) {
        setPlansData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCheckoutURL = async (plan_id) => {
    try {
      const res = await instance.post(
        process.env.REACT_APP_BASE_URL + "/whop/checkout-url",
        {
          plan_id,
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetch payment options
    if (!accessToken) {
      toast.error("Create an Account first!");
      window.location.href = "/signup";
    }
    getPlanDetails();
    // const userDetails = axios.get(process.env.REACT_APP_BASE_URL + "/user/details", {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //     },
    // });

    // if(userDetails.data?.subscritptionActive){
    //     setActivePriceId(userDetails.data.planId)
    // }
  }, []);

  const [plans, setPlans] = useState([
    // TODO: Uncomment this when the base plan is ready and the pricing is set
    // {
    //   title: "Scan Profit Base Plan",
    //   features: ["1500 monthly product scans"],
    //   isPopular: false,
    // },
    {
      title: "Scan Profit Pro Plan",
      features: ["Unlimited Product Scans", "Priority support"],
      isPopular: true,
    },
  ]);

  useEffect(() => {
    if (plansData) {
      plans.forEach((plan) => {
        const basic = plansData?.data.find(
          (p) => p.id === process.env.REACT_APP_BASIC_PLAN_ID
        );
        const premium = plansData?.data.find(
          (p) => p.id === process.env.REACT_APP_PREMIUM_PLAN_ID
        );
        if (basic && plan.title === "Scan Profit Base Plan") {
          setPlans((prevPlans) =>
            prevPlans.map((p) =>
              p.title === plan.title ? { ...p, ...basic } : p
            )
          );
        } else if (premium && plan.title === "Scan Profit Pro Plan") {
          setPlans((prevPlans) =>
            prevPlans.map((p) =>
              p.title === plan.title ? { ...p, ...premium } : p
            )
          );
        }
      });
    }
  }, [plansData]);

  const handleStartTrial = async (plan) => {
    setIsLoading(plan.id);
    if(!isLoading){
      const res = await getCheckoutURL(plan.id);
      if(res){
        window.location.href = res.purchase_url;
      }
    }
    setIsLoading(null);
  };

  return (
    <>
      <div
        className={`px-5 py-3.5 flex  w-full justify-between items-center font-Inter`}
      >
        <div className="flex items-center space-x-3 w-[60%] max-w-[240px]">
          <img src={logo} className="w-3/4 h-auto" />
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      {/* <div className="grid min-h-screen place-items-center">
        <div className="items-center justify-center w-full">
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PK}
            customer-email={user?.email}
            customer-name={user?.name}
          ></stripe-pricing-table>
        </div>
      </div> */}
      <div className="grid min-h-screen place-items-center">
        <div className="flex flex-col md:flex-row justify-center space-x-0 md:space-x-8 space-y-8 md:space-y-0 p-8">
          {plans.map((plan, index) => (
            <PlanCard
              key={index}
              planId={plan.id}
              title={plan.title}
              price={plan.renewal_price}
              features={plan.features}
              isPopular={plan.isPopular}
              isLoading={isLoading}
              onStartTrial={() => handleStartTrial(plan)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentTables;

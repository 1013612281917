import React, { useState, useEffect } from "react";
import { IoSearchOutline, IoAdd } from "react-icons/io5";
import { FiCopy } from "react-icons/fi";
import ProductInfo from "../components/ProductInfo";
import Offers from "../components/Offers";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getKeepaChart,
  getSellerData,
  getSellerProducts,
} from "../redux/actions/storefront";
import StorefrontSkeleton from "../components/skeleton/StorefrontSkeleton";
import ProductSkeleton from "../components/skeleton/ProductSkeleton";
import ProductLoadingSkeleton from "../components/skeleton/ProductLoadingSkeleton";
import SellerNotFound from "../components/ErrorPages/SellerNotFound";
import { toast } from "sonner";
import formatNumber from "../components/helpers/formatNumber";
import OfferChart from "../components/OfferChart";
import { usePostHog } from "posthog-js/react";

const Storefront = () => {
  const [userInput, setUserInput] = useState("");
  const [categoryCount, setCategoryCount] = useState(4);
  const [brandCount, setBrandCount] = useState(4);
  const [productCount, setProductCount] = useState(5);
  const [sellerFound, setSellerFound] = useState(true);
  const posthog = usePostHog();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (user) {
      posthog?.identify(user?.email, {
        name: user?.name,
      });
    }
  }, [user]);

  const {
    sellerInfoLoading,
    data,
    sellerProductLoading,
    sellerProductData,
    moreProductsLoading,
    chartUrls,
  } = useSelector((state) => state.storefront);
  const dispatch = useDispatch();
  const sellerDetails = [
    {
      title: "Seller Name",
      value: data?.basicInfo?.sellerName,
    },
    {
      title: "Seller ID",
      value: data?.basicInfo?.sellerId,
    },
    {
      title: "Rating",
      value: `${data?.ratingInfo?.rating}% (${formatNumber(
        data?.ratingInfo?.ratingCount
      )})`,
    },
    {
      title: "ASIN Count",
      value: formatNumber(data?.asinCount),
    },
  ];
  const topBrands = [
    {
      title: "Cos de Baha",
      value: 5,
    },
    {
      title: "Essie",
      value: 2,
    },
    {
      title: "LÓreal Paris",
      value: 2,
    },
    {
      title: "Hallmark",
      value: 2,
    },
  ];
  const topCategories = [
    {
      title: "Beauty and Personal Care",
      value: 11,
    },
    {
      title: "Patio, Lawn & Garden",
      value: 4,
    },
    {
      title: "Health & Household",
      value: 4,
    },
    {
      title: "Books",
      value: 2,
    },
  ];

  // Function to get query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("q"); // Get the value of the 'q' parameter
  };

  useEffect(() => {
    const queryValue = getQueryParams();
    if (queryValue) {
      setUserInput(queryValue);
      handleSearch(queryValue);
    }
  }, []);
  const handleSearch = async (q) => {
    setSellerFound(true);
    // start loading
    dispatch({
      type: "TOGGLE_SELLER_LOADING",
      payload: true,
    });
    dispatch({
      type: "TOGGLE_PRODUCT_LOADING",
      payload: true,
    });
    // fetch seller Data
    let sellerData = null;
    console.log("Query");
    console.log(q);
    if (q) {
      sellerData = await getSellerData(q);
    } else {
      sellerData = await getSellerData(userInput);
    }
    posthog.capture("storefront_stalk", {
      query: q || userInput,
    });
    if (sellerData.data) {
      dispatch({
        type: "SET_SELLER_DATA",
        payload: sellerData.data,
      });
      const asins = sellerData.data?.asins?.slice(0, productCount);
      const productData = await getSellerProducts(
        asins,
        sellerData.data?.basicInfo?.sellerId
      );
      if (productData.data) {
        const urls = await Promise.all(asins.map(asin => getKeepaChart(asin)));
      
        urls.forEach(url => {
          dispatch({
            type: "SET_KEEPA_CHARTS",
            payload: url,
          });
        });
      
        dispatch({
          type: "SET_SELLER_PRODUCTS",
          payload: productData.data,
        });
      
        setSellerFound(true);
      } else {
        if (productData.status === 403) {
          toast.error("Insufficient lookups!");
        }
        setSellerFound(false);
      }
    } else {
      if (sellerData.status === 403) {
        toast.error("Insufficient lookups!");
      }
      setSellerFound(false);
    }
    dispatch({
      type: "TOGGLE_SELLER_LOADING",
      payload: false,
    });
    dispatch({
      type: "TOGGLE_PRODUCT_LOADING",
      payload: false,
    });
  };

  const handleLoadMore = async () => {
    dispatch({
      type: "TOGGLE_MORE_PRODUCT_LOADING",
      payload: true,
    });
  
    let asins = data?.asins?.slice(productCount, productCount + 5);
    const moreProductData = await getSellerProducts(
      asins,
      data?.basicInfo?.sellerId
    );
  
    const urls = await Promise.all(asins.map(asin => getKeepaChart(asin)));
  
    urls.forEach(url => {
      dispatch({
        type: "SET_KEEPA_CHARTS",
        payload: url,
      });
    });
  
    setProductCount(prev => prev + 5);
    dispatch({
      type: "SET_MORE_PRODUCTS",
      payload: moreProductData?.data,
    });
  };
  

  const handleCopy = (input) => {
    toast.success("SellerID Successfully copied!");
    navigator.clipboard.writeText(input);
  };
  return (
    <div className="bg-primary-bg min-h-screen">
      <p className="title border-b-2 border-b-border w-full p-5 text-gray-100">
        Storefront Stalking
      </p>
      <div className="p-5 flex flex-col gap-y-5">
        <div className="card rounded-xl border-2 border-border flex items-center gap-x-3 p-4">
          <input
            className="outline-none border-none px-3 py-1 rounded-md w-[88%] placeholder:text-gray-700"
            placeholder="Search"
            onChange={(e) => setUserInput(e.target.value)}
            value={userInput}
          />
          <div
            className="px-4 py-2 rounded-md bg-[#235543] cursor-pointer hover:opacity-90"
            onClick={() => handleSearch(null)}
          >
            <IoSearchOutline className="text-gray-100 w-5 h-5" />
          </div>
          <div
            className="px-4 py-2 rounded-md bg-[#235543] flex items-center gap-x-2 cursor-pointer hover:opacity-90"
            onClick={() => {
              handleCopy(userInput);
              handleSearch(null);
            }}
          >
            <FiCopy className="text-gray-100" />
            <IoAdd className="text-gray-100" />
            <IoSearchOutline className="text-gray-100 w-5 h-5" />
          </div>
        </div>
        {!sellerFound ? <SellerNotFound /> : null}
        {sellerInfoLoading ? (
          <StorefrontSkeleton />
        ) : sellerFound && data ? (
          <>
            <div className="flex justify-between">
              <div className="flex flex-col p-3 rounded-xl border-2 border-border card gap-y-4 w-[32%] h-fit">
                <h1 className="text-primary text-xl font-semibold">
                  Seller Details
                </h1>
                <div className="bg-secondary-bg p-3 rounded-xl flex flex-col gap-y-3">
                  {sellerDetails.map(({ title, value }, i) => {
                    return (
                      <div
                        className="w-full flex items-center justify-between"
                        key={i}
                      >
                        <p className="text-white">{title}</p>
                        <p className="text-primary">{value ? value : "-"}</p>
                      </div>
                    );
                  })}
                  <p className="text-gray-200 text-sm">
                    ASIN, brand and category counts are a guide and not exact.
                  </p>
                </div>
              </div>
              <div className="flex flex-col p-3 rounded-xl border-2 border-border card gap-y-4 w-[32%] h-fit">
                <h1 className="text-primary text-xl font-semibold">
                  Top Brands
                </h1>

                <div className="p-3 w-full flex flex-col gap-y-2 justify-center items-center">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-white w-[55%] text-center">Brand Name</p>
                    <p className="text-white w-[45%] text-center">
                      Product Count
                    </p>
                  </div>
                  {data?.brands
                    ?.slice(0, brandCount)
                    .map(({ brand, productCount }, i) => {
                      return (
                        <div className="flex items-center w-full" key={i}>
                          <p className="text-white py-1.5 rounded-l-full bg-secondary-bg w-[55%] text-center">
                            {brand}
                          </p>
                          <p className="text-primary py-1.5 rounded-r-full bg-secondary-bg w-[45%] text-center">
                            {formatNumber(productCount)}
                          </p>
                        </div>
                      );
                    })}
                  {brandCount < data?.brands?.length ? (
                    <button
                      className="bg-secondary-bg flex items-center justify-center w-full px-16 font-medium rounded-lg py-2 text-gray-100 mt-2"
                      onClick={() => setBrandCount((prev) => prev + 4)}
                    >
                      Load More
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col p-3 rounded-xl border-2 border-border card gap-y-4 w-[32%]">
                <h1 className="text-primary text-xl font-semibold">
                  Top Categories
                </h1>

                <div className="p-3 w-full flex flex-col gap-y-2 justify-center items-center">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-white w-[60%] text-center">
                      Category Name
                    </p>
                    <p className="text-white w-[40%] text-center">
                      Product Count
                    </p>
                  </div>
                  {data?.categories
                    ?.slice(0, categoryCount)
                    .map(({ name, productCount }, i) => {
                      return (
                        <div
                          className="flex items-center justify-between w-full"
                          key={i}
                        >
                          <p className="text-white py-1.5 rounded-l-full bg-secondary-bg w-[60%] text-center">
                            {name}
                          </p>
                          <p className="text-primary py-1.5 rounded-r-full bg-secondary-bg w-[40%] text-center">
                            {formatNumber(productCount)}
                          </p>
                        </div>
                      );
                    })}
                  {categoryCount < data?.categories?.length ? (
                    <button
                      className="bg-secondary-bg flex items-center justify-center w-full px-16 font-medium rounded-lg py-2 text-gray-100 mt-2"
                      onClick={() => setCategoryCount((prev) => prev + 4)}
                    >
                      Load More
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {sellerProductLoading ? (
          <ProductSkeleton />
        ) : sellerFound && sellerProductData ? (
          <div className="flex flex-col gap-y-4 card border-2 border-border rounded-xl p-4">
            <div className="flex items-center text-primary text-xl font-semibold justify-between">
              <p className="w-[38%]">Product</p>
              <p className="w-[30%]">Top 5 Offers</p>
              <p className="w-[30%]">Graph</p>
            </div>
            <div className="flex flex-col gap-y-3">
              {sellerProductData?.map((product, i) => {
                return (
                  <div
                    className="flex border-2 border-border rounded-xl p-4 justify-between"
                    key={i}
                  >
                    <div className="w-[38%]">
                      <ProductInfo productData={product} />
                    </div>
                    <div className="w-[30%]">
                      <Offers offersData={product.offers} />
                    </div>
                    <div className="rounded-xl items-center flex justify-center w-[30%] h-full flex-col">
                      {chartUrls[i] ? (
                        <img
                          src={chartUrls[i]}
                          alt="Keepa chart"
                          className="rounded-lg"
                        />
                      ) : null}
                      <OfferChart data={product.offerChartData} />
                    </div>
                  </div>
                );
              })}
              {moreProductsLoading ? <ProductLoadingSkeleton /> : null}
            </div>
            {productCount < data?.asins?.length ? (
              <button
                className="bg-secondary-bg mx-auto w-1/4 rounded-xl text-white py-2"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Storefront;

import React, { useState } from 'react';
import axios from 'axios';
import instance from '../redux/actions/AxiosInstance/authenticated';

const SupportModal = ({ isOpen, onClose, onSubmit }) => {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('idle'); // idle, sending, success, error

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setStatus('sending');
      
      // Send support request to backend
      await instance.post('/user/support', {
        message
      });

      setStatus('success');
      setMessage('');
      
      // Close modal after 3 seconds
      setTimeout(() => {
        onClose();
        setStatus('idle');
      }, 3000);

    } catch (error) {
      setStatus('error');
      setTimeout(() => setStatus('idle'), 3000);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-xl font-bold mb-4">Support Request</h2>
        
        {status === 'success' ? (
          <div className="text-center py-8">
            <div className="text-green-600 mb-2">✓</div>
            <p className="text-lg font-medium">Message Sent!</p>
            <p className="text-gray-600">We will get back to you shortly.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <textarea
              className="w-full h-32 p-2 border border-gray-300 rounded mb-4"
              placeholder="How can we help you?"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              disabled={status === 'sending'}
            />
            {status === 'error' && (
              <p className="text-red-500 mb-2">Failed to send message. Please try again.</p>
            )}
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                disabled={status === 'sending'}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-4 py-2 bg-primary-green text-white rounded hover:bg-opacity-90 flex items-center ${
                  status === 'sending' ? 'opacity-75 cursor-not-allowed' : ''
                }`}
                disabled={status === 'sending'}
              >
                {status === 'sending' ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Sending...
                  </>
                ) : (
                  'Send'
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SupportModal;
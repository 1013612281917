import React from "react";

const PlanCard = ({
  planId,
  title,
  price,
  features,
  isLoading,
  isPopular,
  onStartTrial,
}) => {
  return (
    <div
      className={`flex flex-col justify-center max-w-sm rounded-lg p-6 ${
        isPopular ? "bg-gray-200 shadow-lg border border-gray-300" : "bg-white"
      } sm:max-w-md md:max-w-lg lg:max-w-xl`}
    >
      {isPopular && (
        <div className="mb-4">
          <span className="bg-white text-gray-400 text-xs font-semibold px-2 py-1 rounded-md">
            Most popular
          </span>
        </div>
      )}
      <h2 className="text-2xl font-semibold text-center mb-4">{title}</h2>
      <div className="text-center text-4xl font-bold mb-4 flex items-center">
        <span>${price}</span>
        <div className="text-sm flex flex-col leading-tight text-left text-gray-400 ml-1">
          <span className="font-normal">per</span>
          <span className="font-normal">month</span>
        </div>
      </div>
      <button
        onClick={onStartTrial}
        className="w-full bg-text-dark text-white font-semibold py-2 px-4 rounded hover:bg-green-800 transition duration-300 flex justify-center items-center"
        style={{ minHeight: "40px" }}
      >
        {isLoading === planId ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M12 2a10 10 0 0110 10h-4a6 6 0 00-6-6V2z"
            ></path>
          </svg>
        ) : (
          "Start trial"
        )}
      </button>
      <p className="my-2">This includes:</p>
      <ul className="mb-2">
        {features.map((feature, index) => (
          <li key={index} className="text-gray-700 mb-2 flex items-center">
            <svg
              className="w-4 h-4 text-gray-500 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4"
              />
            </svg>
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlanCard;
